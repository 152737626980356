import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";

//Promotion
const PromotionAdd = ScreenLoader(
  lazy(() => import("../screens/promotion-screen/PromotionAdd")),
);
const PromotionReport = ScreenLoader(
  lazy(() => import("../screens/promotion-screen/PromotionReport")),
);
const PromotionManagement = ScreenLoader(
  lazy(() => import("../screens/promotion-screen/PromotionManagement")),
);
const PromotionEdit = ScreenLoader(
  lazy(() => import("../screens/promotion-screen/PromotionEdit")),
);

export const PromotionRoute = () => ({
  path: "promotion",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [
    { path: "promotion-add", element: <PromotionAdd /> },
    { path: "promotion-report", element: <PromotionReport /> },
    { path: "promotion-management", element: <PromotionManagement /> },
    { path: "promotion-edit/:id", element: <PromotionEdit /> },
  ],
});

import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";

// Distributor
const DistributorProvider = ScreenLoader(
  lazy(() => import("../screens/distributor-screen/DistributorProvider")),
);

export const DistribustorRoute = () => ({
  path: "distributor",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [{ path: "providers", element: <DistributorProvider /> }],
});

import { InfinitySpin } from "react-loader-spinner";

function LoadingPage() {
    return (
        <div className="flex flex-col justify-center items-center w-full h-full">
            <InfinitySpin
                width='200'
                color="#3f2df1"
            />
            <h1 className="text-indigo-500 text-2xl">Wait a sec...</h1>
        </div>
    );
}
export default LoadingPage;
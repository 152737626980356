import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";

const Reconcile = ScreenLoader(
  lazy(() => import("../screens/reconcile-screen/ReconcileAll")),
);
export const ReconcileRoute = () => ({
  path: "reconcile",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Reconcile />
      </AuthGuard>
    </AuthContextProvider>
  ),
});

import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";
//Website
const PaymentGatewayList = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayList")),
);
const PaymentGatewaySetting = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewaySetting")),
);
const PaymentGatewayAutoList = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayAutoList")),
);
const PaymentGatewayAutoTransactions = ScreenLoader(
  lazy(
    () => import("../screens/payment-screen/PaymentGatewayAutoTransactions"),
  ),
);
const PaymentGatewayAutoCreate = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayAutoCreate")),
);
const PaymentGatewayAutoTransfer = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayAutoTransfer")),
);

export const PaymentGatewayRoute = () => ({
  path: "payment-gateway",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [
    { path: "list", element: <PaymentGatewayList /> },
    { path: "setting", element: <PaymentGatewaySetting /> },
  ],
});

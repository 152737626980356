import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";
//Website
const WebsiteSystemSetting = ScreenLoader(
  lazy(() => import("../screens/website-screen/WebsiteSystemSetting")),
);
const GameProviders = ScreenLoader(
  lazy(() => import("../screens/website-screen/GameProviders")),
);
const GameType = ScreenLoader(
  lazy(() => import("../screens/website-screen/GameType")),
);
const WebsiteConfig = ScreenLoader(
  lazy(() => import("../screens/website-screen/WebsiteConfig")),
);
const WebsiteBanner = ScreenLoader(
  lazy(() => import("../screens/website-screen/WebsiteBanner")),
);
const WebsiteBannerAdd = ScreenLoader(
  lazy(() => import("../screens/website-screen/WebsiteBannerAdd")),
);
const WebsiteCustomize = ScreenLoader(
  lazy(() => import("../screens/website-screen/WebsiteCustomize")),
);
const WebsitePopup = ScreenLoader(
  lazy(() => import("../screens/website-screen/WebsitePopup")),
);
const DistributorProvider = ScreenLoader(
  lazy(() => import("../screens/distributor-screen/DistributorProvider")),
);

export const WebsiteRoute = () => ({
  path: "website",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [
    { path: "game-providers", element: <DistributorProvider /> },
    { path: "game-types", element: <GameType /> },
    { path: "core-system", element: <WebsiteSystemSetting /> },
    { path: "website-config", element: <WebsiteConfig /> },
    { path: "website-banner", element: <WebsiteBanner /> },
    { path: "website-banner-add", element: <WebsiteBannerAdd /> },
    { path: "website-customize", element: <WebsiteCustomize /> },
    { path: "website-popup", element: <WebsitePopup /> },
  ],
});

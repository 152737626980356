import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";

const EmployeeAdd = ScreenLoader(
  lazy(() => import("../screens/employee-screen/EmployeeAdd")),
);
const EmployeeManagement = ScreenLoader(
  lazy(() => import("../screens/employee-screen/EmployeeManagement")),
);
const AdminLog = ScreenLoader(
  lazy(() => import("../screens/employee-screen/AdminLog")),
);

export const EmployeeRoute = () => ({
  path: "employee",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [
    { path: "employee-add", element: <EmployeeAdd /> },
    { path: "employee-management", element: <EmployeeManagement /> },
    { path: "admin-log", element: <AdminLog /> },
  ],
});

import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";

//Member
const MemberAdd = ScreenLoader(
  lazy(() => import("../screens/member-screen/MemberAdd")),
);
const MemberChangePassword = ScreenLoader(
  lazy(() => import("../screens/member-screen/MemberChangePassword")),
);
const MemberChangeAccount = ScreenLoader(
  lazy(() => import("../screens/member-screen/MemberChangeAccount")),
);
const MemberManagement = ScreenLoader(
  lazy(() => import("../screens/member-screen/MemberManagement")),
);
const MemberDetail = ScreenLoader(
  lazy(() => import("../screens/member-screen/member-detail/MemberDetail")),
);

export const MemberRoute = () => ({
  path: "member",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [
    { path: "member-add", element: <MemberAdd /> },
    { path: "member-management", element: <MemberManagement /> },
    { path: "member-detail/:id", element: <MemberDetail /> },
    { path: "change-password/:id", element: <MemberChangePassword /> },
    { path: "change-account/:id", element: <MemberChangeAccount /> },
  ],
});

import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";
//Website
const PaymentGatewayAutoList = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayAutoList")),
);
const PaymentGatewayAutoTransactions = ScreenLoader(
  lazy(
    () => import("../screens/payment-screen/PaymentGatewayAutoTransactions"),
  ),
);
const PaymentGatewayAutoCreate = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayAutoCreate")),
);
const PaymentGatewayAutoTransfer = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayAutoTransfer")),
);
const PaymentGatewayAutoApproveTransfer = ScreenLoader(
  lazy(
    () => import("../screens/payment-screen/PaymentGatewayAutoApproveTransfer"),
  ),
);

export const PaymentAutoRoute = () => ({
  path: "payment-auto",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [
    { path: "list", element: <PaymentGatewayAutoList /> },
    {
      path: "transactions/:id",
      element: <PaymentGatewayAutoTransactions />,
    },
    { path: "add", element: <PaymentGatewayAutoCreate /> },
    { path: "transfer", element: <PaymentGatewayAutoTransfer /> },
    {
      path: "approve-transfer",
      element: <PaymentGatewayAutoApproveTransfer />,
    },
  ],
});

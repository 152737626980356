import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";

const AgentReport = ScreenLoader(
  lazy(() => import("../screens/agent-screen/AgentReport")),
);

const AgentDailyReport = ScreenLoader(
  lazy(() => import("../screens/agent-screen/AgentDailyReport")),
);

export const ReportRoute = () => ({
  path: "report",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [
    { path: "total-user", element: <AgentReport /> },
    { path: "total-daily", element: <AgentDailyReport /> },
  ],
});

import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";

const Dashboard = ScreenLoader(lazy(() => import("../screens/Dashboard")));
export const DashboardRoute = () => ({
  path: "dashboard",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Dashboard />
      </AuthGuard>
    </AuthContextProvider>
  ),
});

import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";

//refund
const ReturnManagement = ScreenLoader(
  lazy(() => import("../screens/refund-screen/RefundManagement")),
);
const ReturnReport = ScreenLoader(
  lazy(() => import("../screens/refund-screen/RefundReport")),
);
const ReturnSetting = ScreenLoader(
  lazy(() => import("../screens/refund-screen/RefundSetting")),
);
const ReturnEdit = ScreenLoader(
  lazy(() => import("../screens/refund-screen/RefundEdit")),
);

export const RefundPromotionRoute = () => ({
  path: "refund",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [
    { path: "refund-management", element: <ReturnManagement /> },
    { path: "refund-report", element: <ReturnReport /> },
    { path: "refund-setting", element: <ReturnSetting /> },
    { path: "refund-setting/:id", element: <ReturnEdit /> },
  ],
});

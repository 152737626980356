import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";

const AffiliateManagement = ScreenLoader(
  lazy(() => import("../screens/affiliate-screen/AffiliateManagement")),
);
const AffiliateReport = ScreenLoader(
  lazy(() => import("../screens/affiliate-screen/AffiliateReport")),
);
const AffiliateSetting = ScreenLoader(
  lazy(() => import("../screens/affiliate-screen/AffiliateSetting")),
);

export const AffiliatePromotionRoute = () => ({
  path: "affiliate",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [
    { path: "affiliate-management", element: <AffiliateManagement /> },
    { path: "affiliate-report", element: <AffiliateReport /> },
    { path: "affiliate-setting", element: <AffiliateSetting /> },
    { path: "affiliate-setting/:id", element: <AffiliateSetting /> },
  ],
});

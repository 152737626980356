import { Suspense } from "react";
import LoadingPage from "../screens/LoadingPage";

export const ScreenLoader = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Component {...props} />
    </Suspense>
  );
};

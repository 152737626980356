import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";

//Marketing
const MarketingAdd = ScreenLoader(
  lazy(() => import("../screens/marketing-screen/MarketingAdd")),
);
const MarketingManagement = ScreenLoader(
  lazy(() => import("../screens/marketing-screen/MarketingManagement")),
);

export const MarketingRoute = () => ({
  path: "marketing",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [
    { path: "marketing-add", element: <MarketingAdd /> },
    { path: "marketing-management", element: <MarketingManagement /> },
  ],
});

import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";

//Announcement
const AnnouncementAdd = ScreenLoader(
  lazy(() => import("../screens/announcement-screen/AnnouncementAdd")),
);
const AnnouncementManagement = ScreenLoader(
  lazy(() => import("../screens/announcement-screen/AnnouncementManagement")),
);
const AnnouncementEdit = ScreenLoader(
  lazy(() => import("../screens/announcement-screen/AnnouncementEdit")),
);

export const AnnoucementRoute = () => ({
  path: "announcement",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [
    { path: "announcement-add", element: <AnnouncementAdd /> },
    {
      path: "announcement-management",
      element: <AnnouncementManagement />,
    },
    { path: "announcement-edit/:id", element: <AnnouncementEdit /> },
  ],
});

import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";

const Setting = ScreenLoader(lazy(() => import("../screens/Setting")));

export const SettingRoute = () => ({
  path: "setting",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Setting />
      </AuthGuard>
    </AuthContextProvider>
  ),
});

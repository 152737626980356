import { createContext, useCallback, useContext, useState } from "react";
import { InfinitySpin } from "react-loader-spinner";

interface LoadingContextProps {
  push: () => void;
  pop: () => void;
}

const LoadingContext = createContext<LoadingContextProps>({
  push: () => {},
  pop: () => {},
});

export const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean[]>([]);

  const push = useCallback(() => {
    setLoading((prev) => [...prev, true]);
  }, []);

  const pop = useCallback(() => {
    setLoading((prev) => prev.slice(1));
  }, []);

  return (
    <LoadingContext.Provider value={{ push, pop }}>
      {loading.length > 0 && (
        <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-gray-700 opacity-75">
          <InfinitySpin width="200" color="#3f2df1" />
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  );
};

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}

import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";

const BetReport = ScreenLoader(lazy(() => import("../screens/BetReport")));
export const BetReportRoute = () => ({
  path: "bet-report",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <BetReport />
      </AuthGuard>
    </AuthContextProvider>
  ),
});

import { Navigate } from "react-router";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { lazy } from "react";

const LoginPage = ScreenLoader(lazy(() => import("../screens/LoginPage")));

export const RootRoute = () => ({
  path: "/",
  element:
    localStorage.getItem("NAGA_ADMIN:token") !== null ? (
      <Navigate to="/dashboard" replace />
    ) : (
      <AuthContextProvider>
        <LoginPage />
      </AuthContextProvider>
    ),
});

import { Navigate } from "react-router";

export const AuthGuard = ({ children }: { children: React.ReactNode }) => {
  const storedToken = localStorage.getItem("NAGA_ADMIN:token");
  if (storedToken != null && storedToken) {
    return <>{children}</>;
  } else {
    return <Navigate to="/" replace />;
  }
};

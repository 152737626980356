import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";

const CreditTransaction = ScreenLoader(
  lazy(() => import("../screens/CreditTransaction")),
);
export const CreditTrancsactionRoute = () => ({
  path: "credit-transaction",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <CreditTransaction />
      </AuthGuard>
    </AuthContextProvider>
  ),
});
